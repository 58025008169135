import slide from './utils/slick.min';
import slideSetting from './utils/slick_setting';
import smoothscroll from 'smoothscroll-polyfill';


// ▼ ページ内スクロール
class Main1 {
  constructor() {
    smoothscroll.polyfill();

    const headerH = document.querySelector('.l-header').offsetHeight;
    const pageLink = document.querySelectorAll('a[href^="#"]');

    for (let i = 0; i < pageLink.length; i++) {
      const element = pageLink[i];
      element.addEventListener('click', function(e) {
        e.preventDefault();
        const href = this.getAttribute('href');
        
        if (href != '#modal') {
          let target;
          if (href == "#" || href == "" ) {
            target = document.querySelector('html');
          } else {
            target = document.querySelector(href);
          }
          const position = target.getBoundingClientRect().top;
          const offsetTop = window.pageYOffset;
          const positionTop = position + offsetTop - headerH;
          window.scrollTo({
            top: positionTop,
            behavior: 'smooth',
          });
        }
      });
    }
  }
}


// ▼ 空のリンクを無効化
class Main2 {
  constructor() {
    const noLink = document.querySelectorAll('a[href=""]');
    for (let i = 0; i < noLink.length; i++) {
      const element = noLink[i];
      element.addEventListener('click', function(e) {
        e.preventDefault();
      });      
    }
  }
}


// ▼ スクロール時のヘッダーの挙動
class Main3 {
  constructor() {
    const nav = document.querySelector('header');
    const navHeight = nav.offsetHeight;
    const navPos = navHeight;
    const navFixPos = 500;
    const waitClass = '-wait';
    const adjustClass = '-adjust';
    const fixedClass = '-fixed';

    window.addEventListener('scroll', function() {
      let value = this.pageYOffset;
      if ( value > navPos ) {
        nav.classList.add(waitClass);
      } else {
        nav.classList.remove(waitClass);
        nav.classList.remove(adjustClass);
      }
      if ( value > navFixPos ) {
        nav.classList.add(fixedClass);
        nav.classList.add(adjustClass);
      } else {
        nav.classList.remove(fixedClass);
      }
    });
  }
}


// ▼ ドロップダウンメニュー
class Main4 {
  constructor() {
    const gnavBtn = document.querySelector('.btn-gnav_link');

    gnavBtn.addEventListener('click', gnavBox_open);
  
    window.addEventListener('click', function(e) {
      if (e.target.closest('.btn-gnav_link') == null && e.target.closest('.header_menu') == null) {
        gnavBox_close();
      }
    });
  
    // ナビ開閉
    function gnavBox_open() {
      document.querySelector('.l-header').classList.toggle('-open');
      $(this).parents('.l-header').children(".header_menu").slideToggle();
      return false;
    }
    function gnavBox_close() {
      document.querySelector('.l-header').classList.remove('-open');
      $(".header_menu").slideUp();
      return false;
    }
  }
}


// ▼ SP版グローバルメニューアコーディオン（header・footer共通）
class Main5 {
  constructor() {
    const cateBtn = document.querySelectorAll('.gnav_arw');

    for (let i = 0; i < cateBtn.length; i++) {
      const element = cateBtn[i];
      element.addEventListener('click', cateBox_accordion);
    }

    function cateBox_accordion() {
      $(this).parent().siblings(".gnav_ttl").removeClass("-open").next('.gnav_list').slideUp();
      $(this).closest(".gnav_col").siblings().children(".gnav_list").slideUp().siblings(".gnav_ttl").removeClass("-open");
      $(this).parent().next(".gnav_list").slideToggle();
      this.parentNode.classList.toggle('-open');
      return false;
    }
  }
}


// ▼ ヘッダー固定表示（WPツールバー対応）
class Main6 {
  constructor() {
    const header = document.querySelector('.l-header');
    const adminBar = document.querySelector('#wpadminbar');
    const pageWrap = document.querySelector('#page');

    if (adminBar !== null) {
      hdPos();
      window.onresize = hdPos;
    }

    function hdPos() {
      const wpBarH = adminBar.offsetHeight;
      const style = window.getComputedStyle(adminBar);
      const wpBarPos = style.position;

      if (wpBarPos === 'fixed') {
        header.style.top = wpBarH + 'px';
        pageWrap.style.position = '';
      } else {
        header.style.top = 0;
        pageWrap.style.position = 'relative';
      }
    }
  }
}


// ▼ 外部サイト自動_blank
class Main7 {
  constructor() {
    const domain = location.href.match(/^http?(s)?(:\/\/[a-zA-Z0-9-.:]+)/i)[0];
    const bnrLink = document.querySelectorAll('.bnr-head a[href^=http]');

    for (let i = 0; i < bnrLink.length; i++) {
      const element = bnrLink[i];
      if(!element.classList.contains('[href*="' + domain + '"]')) {
        element.setAttribute('target', '_blank');
      }
    }
  }
}


// ▼ トピックスカテゴリタブ
class Main8 {
  constructor() {
    const tabLink = document.querySelectorAll('.topicsCat_tab a');
    const tabItem = document.querySelectorAll('.topicsCat_tab');
    const tabPanel = document.querySelectorAll('.topicsCat_panel');

    for (let i = 0; i < tabLink.length; i++) {
      const element = tabLink[i];
      element.addEventListener('click', function() {
        for (let i = 0; i < tabItem.length; i++) {
          const element = tabItem[i];
          element.classList.remove('current');
        }
        this.parentNode.classList.add('current');

        for (let i = 0; i < tabPanel.length; i++) {
          const element = tabPanel[i];
          element.classList.remove('current');
        }
        tabPanel[i].classList.add('current');
      });
    }
  }
}



window.addEventListener('DOMContentLoaded', () => {
  new Main1();
  new Main2();
  new Main3();
  new Main4();
  new Main5();
  new Main6();
  new Main7();
  new Main8();
});